.desktop_btns_issue_container_{
    display: flex;
}
.mobile_btns_issue_container_{
    display: none!important;
}
/* .MuiDialog-root {
    display: none!important;
} */
@media only screen and (max-width: 700px) {

    .desktop_btns_issue_container_{
        display: none!important;
    }
    .mobile_btns_issue_container_{
        display: flex!important;
    }
	.MuiPaper-root {
        margin:0!important;
        width: 100vw!important;
    }
    .MuiDialogTitle-root {
        width: 100%!important;
    }
    .MuiDialog-container
    {
        width: 100vw!important;
    }
    #root {
        overflow-x: hidden!important;
    }
    .mobile_btns_issue_container_ {
        flex-direction: column;
        flex-grow: unset!important;
        width: 125px!important;
    }
    .mobile_btns_issue_container_ .btn.btn-sm.btn-label-dark{
        width: 125px!important;
        height: auto!important;
        margin-top: 5px!important;
        margin-left: 0!important;
    }
    .mobile_btns_issue_container_ .kt-section__content {
        width: 125px!important;
        margin-left: 0!important;
    }
    .mobile_btns_issue_container_ .ic_container_{
        margin-left:-10px!important;
        margin-bottom: 5px!important;
    }
    .reports__ .MuiPaper-root {
        width: 100%!important;
    }
    .dashboard_row__ .kt-widget4__info {
        width: auto!important;
    }
  }

@media only screen and (min-width: 700px) {
    .dialog_bigger_width .MuiPaper-root {
        width: 80vw;
    }
}

.popup-bottom, .popup-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

