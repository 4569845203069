.after_dropdown_ i{
content: icon;
text-align: center;
display: inline;
border: 0;
font-size: 0.6rem;
line-height: 0;
vertical-align: middle;
position: relative;
margin-left: 0.5rem/*rtl:ignore*/;
opacity: 0.7;
font-family: Flaticon2;
font-style: normal;
font-weight: normal;
font-feature-settings: normal;
font-variant: normal;
line-height: 1;
text-decoration: inherit;
text-rendering: optimizeLegibility;
text-transform: none;
-moz-osx-font-smoothing: grayscale;
-webkit-font-smoothing: antialiased;
font-smoothing: antialiased;
	
}